module.exports = [{
      plugin: require('/codebuild/output/src862270940/src/gatsby-starter-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/codebuild/output/src862270940/src/gatsby-starter-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144356812-1"},
    },{
      plugin: require('/codebuild/output/src862270940/src/gatsby-starter-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src862270940/src/gatsby-starter-blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/codebuild/output/src862270940/src/gatsby-starter-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
